<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="card border-0 shadow-sm">
            <div class="card-header">
              <div class="row">
                <div class="col-auto my-auto me-auto">
                  <h4 class="mb-0">Form Templates</h4>
                </div>
                <div class="col-md-8 my-auto ms-auto text-right">
                  <span class="d-inline-block">
                    <div class="dropdown me-3">
                      <a
                        id="filterBySource"
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Filter Status
                        <span class="caret"></span>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right"
                        style="max-height: 300px; overflow: scroll"
                        aria-labelledby="filterBySource"
                      >
                        <div
                          class="dropdown-item cursor-pointer"
                          @click="clearFilters"
                        >
                          All
                        </div>
                        <div
                          class="dropdown-item cursor-pointer"
                          @click="filterBySource('public')"
                        >
                          Public
                        </div>
                        <div
                          class="dropdown-item cursor-pointer"
                          @click="filterBySource('private')"
                        >
                          Private
                        </div>
                      </div>
                    </div>
                  </span>

                  <span class="d-inline-block">
                    <div class="dropdown me-3">
                      <a
                        id="filterBySource"
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Filter Category
                        <span class="caret"></span>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right"
                        style="max-height: 300px; overflow: scroll"
                        aria-labelledby="filterBySource"
                      >
                        <div
                          class="dropdown-item cursor-pointer"
                          @click="clearFilters"
                        >
                          All
                        </div>
                        <div
                          v-for="c in categories"
                          class="dropdown-item cursor-pointer"
                          @click="filterByCategory(c)"
                        >
                          {{ c }}
                        </div>
                      </div>
                    </div>
                  </span>

                  <!--  -->

                  <span class="d-inline-block">
                    <button class="btn btn-light" @click="goToNewForm">
                      <i class="fad fa-plus me-2"></i>Add Form Template
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-hover" v-if="forms.length > 0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Created</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="f in forms" :key="f.id" class="cursor-pointer">
                    <th scope="row" @click="editForm(f.id)">#{{ f.id }}</th>
                    <td @click="editForm(f.id)">{{ f.title }}</td>
                    <td @click="editForm(f.id)">{{ f.category }}</td>
                    <td @click="editForm(f.id)">
                      {{ f.created_at | formatDate }}
                    </td>
                    <td
                      @click="editForm(f.id)"
                      class="text-capitalize text-nowrap"
                    >
                      {{ f.status }}
                      <span
                        v-if="f.is_public"
                        class="ms-2 badge badge-success badge-pill"
                        >Public</span
                      >
                    </td>
                    <td class="text-nowrap text-right">
                      <span>
                        <button
                          class="btn btn-light btn-sm me-2"
                          @click="assignForm(f)"
                          v-tooltip.bottom="'Assign to Practitioner'"
                          v-if="!f.is_public"
                        >
                          <i class="fad fa-user"></i>
                        </button>
                        <button
                          class="btn btn-light btn-sm me-2"
                          v-tooltip.bottom="'Duplicate Form'"
                          @click="duplicateForm(f.id)"
                        >
                          <i class="fad fa-copy"></i>
                        </button>
                        <button
                          class="btn btn-light btn-sm me-2"
                          v-tooltip.bottom="'Delete Form'"
                          @click="deleteForm(f.id)"
                        >
                          <i class="fad fa-trash text-danger"></i>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row my-5" v-if="forms.length == 0">
                <div class="col text-center">
                  <i
                    class="fad fa-exclamation-triangle fa-6x text-success mb-5"
                  ></i>
                  <h5 class="mb-5">
                    There are no form templates matching your filters
                  </h5>

                  <button
                    class="btn btn-lg btn-light ms-3"
                    @click="clearFilters"
                  >
                    <i class="fad fa-times me-2"></i>Clear Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <assign-form
      v-if="formToAssign"
      :form="formToAssign"
      @close="formToAssign = null"
    ></assign-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forms: [],
      originalForms: [],
      formToAssign: null,
      categories: [
        "Adult Health Intake Questionnaire",
        "Child Health Intake Questionnaire",
        "Food Diary",
        "Other",
      ],
    };
  },
  methods: {
    async fetchForms() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/form-templates")
        .then(({ data }) => {
          this.forms = data;
          this.originalForms = data;
        });
    },
    goToNewForm() {
      this.$router.push("/form-templates/new");
    },
    editForm(id) {
      this.$router.push("/form-templates/" + id);
    },
    deleteForm(id) {
      var confirmed = confirm(
        "Are you sure you wish to delete this template, this action cannot be undone?"
      );
      if (confirmed) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/form-templates/" + id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchForms();
          });
      }
    },
    duplicateForm(id) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/form-templates/" + id + "/duplicate"
        )
        .then(({ data }) => {
          this.fetchForms();
          this.$EventBus.$emit("alert", data);
        });
    },
    assignForm(form) {
      this.formToAssign = form;
    },
    clearFilters() {
      this.forms = [];
      this.forms = this.originalForms;
    },
    filterBySource(source) {
      this.forms = [];
      this.originalForms.map((f) => {
        if (source === "public" && f.is_public) {
          this.forms.push(f);
        }
        if (source === "private" && !f.is_public) {
          this.forms.push(f);
        }
      });
    },
    filterByCategory(category) {
      this.forms = [];
      this.originalForms.map((f) => {
        if (f.category === category) {
          this.forms.push(f);
        }
      });
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  created() {
    this.fetchForms();
  },
};
</script>

<style>
.custom-row-styles {
  cursor: pointer;
}
</style>
